.App {
  text-align: center;
}

.App-header {
  height: 80vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.application-table-cell {
  cursor: pointer;
}

textarea::-webkit-input-placeholder {
  color: #A8B0B9;
  font-size: 14;
  font-style: italic;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #A8B0B9;
  font-size: 14;
  font-style: italic;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #A8B0B9;
  font-size: 14;
  font-style: italic;
}

textarea:-ms-input-placeholder {
  color: #A8B0B9;
  font-size: 14;
  font-style: italic;
}

textarea::placeholder {
  color: #A8B0B9;
  font-size: 14;
  font-style: italic;
}

ul {
  padding-inline-start: 20px;
}